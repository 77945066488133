import joystick from "@joystick.js/ui-canary";
import menu from '../../icons/menu.svg';

const App = joystick.component({
  state: {
    navigation_open: false,
  },
  css: {
    min: {
      width: {
        0: `
          .container {
            position: relative;
          }

          .glowsticks {
            display: inline-grid;
            grid-template-columns: 1fr;
            gap: 50px;
            transform: rotate(100deg);
            position: absolute;
            top: -250px;
            left: 0%;
            margin-left: 20px;
            opacity: 0.4;
            z-index: -1;
          }

          .glowstick {
            width: 400px;
            height: 20px;
            border-radius: 30px;
            background: #eee;
            filter: blur(40px);
          }

          .glowstick-red {
            background: var(--danger);
          }

          .glowstick-green {
            background: var(--success);
          }

          .glowstick-yellow {
            background: var(--warning);
          }          

          .glowstick-blue {
            background: var(--brand);
          }

          .site-navigation {
            position: relative;          
          }

          .site-navigation > .site-navigation-inner {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 20px 0;
          }

          .site-navigation > .site-navigation-inner > a {
            display: flex;
            align-items: center;
            width: 80px;
            text-decoration: none;
          }

          .site-navigation > .site-navigation-inner > a span {
            text-decoration: none;
            font-weight: 500;
            color: #aaa;
            font-size: 15px;
            display: inline-block;
            margin-left: 15px;
          }

          .site-navigation > .site-navigation-inner > a img {
            width: 100%;
            max-width: 100%;
            height: auto;
          }

          .site-navigation > .site-navigation-inner > ul {
            display: none;
            background: rgba(47, 44, 68, 1);
            position: absolute;
            top: 100%;
            margin-top: 15px;
            left: 0;
            right: 0;
            padding: 25px 20px;
            z-index: 99;
            border-radius: 5px;
          }

          .site-navigation > .site-navigation-inner > ul.is-open {
            display: block;
          }

          .site-navigation > .site-navigation-inner > ul > li {
            font-size: 15px;
            line-height: 15px;
          }

          .site-navigation > .site-navigation-inner > ul > li:not(:last-child) {
            margin-bottom: 15px;
          }

          .site-navigation > .site-navigation-inner > ul > li > a {
            color: #aaa;
            font-weight: 500;
            text-decoration: none;
            font-size: 15px;
            line-height: 15px;
          }

          .site-navigation > .site-navigation-inner > ul > li > .mod-plus-button {
            display: inline-flex;
            align-items: center;
            border: 2px solid var(--brand);
            color: var(--brand);
            padding: 11px 16px;
            font-size: 14px;
            border-radius: 30px;
          }
          
          .site-navigation > .site-navigation-inner > ul > li > .mod-plus-button .icon-circle-arrow-up {
            margin-right: 5px;
            font-size: 18px;
            line-height: 18px;
          }

          .site-navigation > .site-navigation-inner > ul > li > .mod-plus-button:active {
            position: relative;
            top: 2px;
          }

          .site-navigation > .site-navigation-inner > ul > li > .mod-plus-button:hover {
            background: var(--brand);
            color: #fff;
          }

          .site-navigation > .site-navigation-inner > ul > li:has(.mod-plus-button) {
            margin-top: 20px;
          }

          .site-navigation > .site-navigation-inner > ul > li > a:hover {
            color: #fff;
          }

          .site-navigation > .site-navigation-inner > ul > li.is-active > a {
            color: var(--brand);
          }

          .site-navigation > .site-navigation-inner > .mobile-navigation-toggle {
            margin-left: auto;
            width: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          .mobile-navigation-toggle svg {
            width: 100%;
            max-width: 100%;
            height: auto;
          }

          .mobile-navigation-toggle svg path {
            fill: rgba(0, 0, 0, 0.3);
          }

          .mobile-navigation-toggle:hover svg path {
            fill: var(--brand);
          }
        `,
        1040: `
          .site-navigation.is-components-page > .site-navigation-inner {
            border-bottom: none;
          }

          .site-navigation > .site-navigation-inner {
            padding: 30px 0px;
          }

          .site-navigation > .site-navigation-inner > a {
            width: 82px;
          }

          .site-navigation > .site-navigation-inner > .mobile-navigation-toggle {
            display: none;
          }

          .site-navigation > .site-navigation-inner > ul {
            display: flex !important;
            align-items: center;
            background: transparent;
            position: static;
            margin: 0 0 0 auto;
            padding: 0px;
            border-bottom: none;
          }        

          .site-navigation > .site-navigation-inner > ul > li:not(:last-child) {
            margin: 0 25px 0 0;
          }

          .site-navigation > .site-navigation-inner > ul > li > a {
            color: #555;
          }

          .site-navigation > .site-navigation-inner > ul > li:has(.mod-plus-button) {
            margin-top: 0px;
          }

          .site-navigation > .site-navigation-inner > ul > li > a:hover {
            color: var(--brand);
          }          
        `,
        1200: `
        `,
        1240: `
        `,
      },
    },
  },
  events: {
    'click .mobile-navigation-toggle': (event = {}, instance = {}) => {
      instance.set_state({ navigation_open: !instance?.state?.navigation_open });
    },
  },
  render: ({ props, component, url, state }) => {
    return `
      <div class="container">
        <div class="glowsticks">
          <div class="glowstick glowstick-yellow"></div>
          <div class="glowstick glowstick-green"></div>
          <div class="glowstick glowstick-blue"></div>
          <div class="glowstick glowstick-red"></div>
        </div>      
        <nav class="site-navigation ${props?.location === 'components' ? 'is-components-page' : ''}">
          <div class="site-navigation-inner">
            <a href="/"><img src="/mod_logo.svg" alt="Mod" /></a>
            <ul class="${state?.navigation_open ? 'is-open' : ''}">
              <li class="${url.is_active('/components') ? 'is-active' : ''}"><a href="/components">Components</a></li>
              
              
              <li><a href="https://docs.mod.cheatcode.co">Docs</a></li>
              <li><a href="/account">Account</a></li>
              <li><a href="/login">Login</a></li>
            </ul>
            <ul class="${state?.navigation_open ? 'is-open' : ''}">
              <li><a href="/plus" class="mod-plus-button"><i class="icon-circle-arrow-up"></i> Get Mod Plus</a></li>
            </ul>
            <div class="mobile-navigation-toggle">${menu}</div>
          </div>
        </nav>
        ${component(props.page, props)}
      </div>
    `;
  },
});

if (
	    typeof window !== 'undefined' &&
	    window.__joystick_should_auto_mount__ === true &&
	    window.__joystick_layout_url__ &&
      window.__joystick_page_url__ &&
      !window.__joystick_hmr_update__ &&
	    joystick &&
	    joystick.mount
	  ) {
	    (async () => {
	      const layout_component_file = await import(window.__joystick_layout_url__);
	      const page_component_file = await import(window.window.__joystick_page_url__);
	      const layout = layout_component_file.default;
	      const page = page_component_file.default;
	      joystick.mount(layout, Object.assign({ ...window.__joystick_ssr_props__ }, { page }), document.getElementById('app'));
	    })();
	  }

	export default App;
	  
